<template>
  <div class="acteur">
    <div class="flex-wrapper">
      <div class="image-wrapper">
        <img :src="srcImg" :alt="altImg">
      </div>
      <div class="content-wrapper">
        <p class="title">{{ title }}</p>
        <p class="annotation">{{ annotation }}</p>
        <p class="content" v-html="content"></p>
      </div>
    </div>
    <slot name="content"></slot>
  </div>
</template>

<script>
export default {
  name: 'ActorBlock',
  props: {
    srcImg: {
      type: String,
      required: true
    },
    altImg: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    annotation: {
      type: String,
      required: true
    },
    content: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
.acteur {
  display: flex;
  flex-direction: column;
  gap: $space-xs;
  background-color: #fff;
  border-radius: $radius;
  margin-bottom: $space-s;
  padding: $space-m;

  .flex-wrapper {
    display: flex;
    align-items: center;

    .image-wrapper {
      align-self: flex-start;
      margin-right: $space-m;
    }

    .content-wrapper {
      height: 100%;
      align-content: center;
      color: $c-text-dark;

      .title {
        font-family: $ff-big;
        font-weight: $fw-l;
        font-size: $fz-xl;
        color: $c-secondary;
      }

      .annotation {
        font-weight: $fw-l;
        font-size: $fz-l;
        margin-bottom: $space-m;
        color: #000;
      }
    }
  }
}
</style>
